import React from "react"
import cursosStyles from "./cursos.module.scss"
import { Link } from "gatsby"
import { BrowserView, MobileView } from "react-device-detect"
import { useStaticQuery, graphql } from "gatsby"

export default function Cursos() {
  let data = useStaticQuery(graphql`
    query CursosCuidades {
      allAirtable(
        filter: { data: { section: { eq: "CursosEnTuCuidad" }, data: {} } }
      ) {
        nodes {
          table
          data {
            Name
            data
            img {
              thumbnails {
                full {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  let cuidades = []
  let cuidad = data.allAirtable.nodes.filter(
    node => node.data.Name === "cuidad"
  )
  let cuidadWithImg = data.allAirtable.nodes.filter(
    node => node.data.Name === "cuidadWithImg"
  )

  cuidad.map((city, index) => {
    cuidades.push({
      city1: city.data.data,
      cityImg: cuidadWithImg[index].data.img[0].thumbnails.full.url,
      cityImgTitle: cuidadWithImg[index].data.data,
    })
  })

  return (
    <div>
      <BrowserView>
        {cuidades.map((cuidad, index) => {
          if (index % 2 === 0) {
            return (
              <div className={cursosStyles.container}>
                <div className={cursosStyles.imgContainer}>
                  <img
                    src={cuidad.cityImg}
                    className={cursosStyles.img}
                    alt="city"
                  />
                  <div className={cursosStyles.opaque}></div>
                  <Link to={"/city/" + cuidad.cityImgTitle}>
                    <h1 className={cursosStyles.title2}>
                      {cuidad.cityImgTitle}
                    </h1>
                  </Link>
                </div>
                <div className={cursosStyles.greenContainer}>
                  <Link to={"/city/" + cuidad.city1}>
                    <h1 className={cursosStyles.title1}>{cuidad.city1}</h1>
                  </Link>
                </div>
              </div>
            )
          } else {
            return (
              <div className={cursosStyles.container}>
                <div className={cursosStyles.greenContainer}>
                  <Link to={"/city/" + cuidad.city1}>
                    <h1 className={cursosStyles.title1}>{cuidad.city1}</h1>
                  </Link>
                </div>
                <div className={cursosStyles.imgContainer}>
                  <img
                    src={cuidad.cityImg}
                    className={cursosStyles.img}
                    alt="city"
                  />
                  <div className={cursosStyles.opaque}></div>
                  <Link to={"/city/" + cuidad.cityImgTitle}>
                    <h1 className={cursosStyles.title2}>
                      {cuidad.cityImgTitle}
                    </h1>
                  </Link>
                </div>
              </div>
            )
          }
        })}
      </BrowserView>
      <MobileView>
        {cuidades.map((cuidad, index) => {
          return (
            <div className={cursosStyles.container}>
              <div className={cursosStyles.imgContainer}>
                <img
                  src={cuidad.cityImg}
                  className={cursosStyles.img}
                  alt="city"
                />
                <div className={cursosStyles.opaque}></div>
                <Link to={"/city/" + cuidad.cityImgTitle}>
                  <h1 className={cursosStyles.title2}>{cuidad.cityImgTitle}</h1>
                </Link>
              </div>
              <div className={cursosStyles.greenContainer}>
                <Link to={"/city/" + cuidad.city1}>
                  <h1 className={cursosStyles.title1}>{cuidad.city1}</h1>
                </Link>
              </div>
            </div>
          )
        })}
      </MobileView>
    </div>
  )
}
